.landingPage {
  margin: 0;
  font-family: Arial, sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: radial-gradient(circle, #ecedee, #e1f0fe, #faece9);
  position: relative;
}

.header {
  display: flex;
  align-items: center;
  padding: 10px;
}

.logo {
  width: 50px;
  margin-right: 15px;
}

.title {
  font-size: 2.5em;
  color: #333;
  margin: 0;
}

.tagline {
  font-size: 1.2em;
  color: #888888;
  margin-top: 10px;
  width: 75%;
  text-align: center;
}

.comingSoon {
  font-size: 0.8em;
  font-style: italic;
  color: #666;
  position: absolute;
  bottom: 20px;
  text-align: center;
  width: 100%;
}

.coming-soon a {
  text-decoration: none;
  color: inherit;
}
